.textWidth {
    width: 60%;
}

@media only screen and (max-width: 768px) {
    .textWidth {
        width: 90%
    }
  }

  body::-webkit-scrollbar{
      display: none;
  }